import { SearchOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './index.module.less';
import { getJewelrySearchSuggestions, logCommonView } from '@/lib/service';
import { getSubcategoryValueToTextMap } from '@/shared/utils/subcategory-helper';
import { SearchSuggestions } from '@/shared/types/marketplace.interface';
import { convertSuggestions, imageTransform, shopAllLink } from '@/shared/utils/utils';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { EventSource } from '@/shared/types/event-types';
import { Input } from 'antd';
import { useDebounce } from 'react-use';
import { isEmpty } from 'lodash';

const UniversalSearch = () => {
  const subcategoryValueMap = getSubcategoryValueToTextMap();
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestions>({});
  const [showSuggestionsContainer, setShowSuggestionsContainer] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const router = useRouter();

  const closeMenu = useCallback(() => {
    setSearchQuery('');
    setSearchSuggestions({});
    setShowSuggestionsContainer(false);
  }, []);

  const searchMarketplace = useCallback(
    (text: string) => {
      router
        .replace(
          shopAllLink(text, {
            text: text,
            scrollToTop: true,
          }),
        )
        .then(() => {
          closeMenu();
          logCommonView(EventSource.SEARCH_TERMS, text);
        });
    },
    [closeMenu, router],
  );

  const handleSuggestionClick = useCallback(
    (search: boolean, text: string = null) => {
      if (search) {
        searchMarketplace(text);
      }
      closeMenu();
    },
    [closeMenu, searchMarketplace],
  );

  const suggestionShopAllLink = useCallback((item: any) => {
    return shopAllLink(item.text, {
      [item.param]: item.value,
    });
  }, []);

  const hasSearchSuggestions = useCallback((obj: any) => {
    return Object.keys(obj).reduce((acc: boolean, key: any) => {
      return acc || !isEmpty(obj[key]);
    }, false);
  }, []);

  const getSearchSuggestions = useCallback(async () => {
    if (isEmpty(searchQuery)) {
      setShowSuggestionsContainer(false);
      return;
    }

    try {
      const data = await getJewelrySearchSuggestions(searchQuery);
      const convertData = convertSuggestions(data, subcategoryValueMap);
      setSearchSuggestions(convertData);
      setShowSuggestionsContainer(hasSearchSuggestions(convertData));
    } catch (e) {}
  }, [hasSearchSuggestions, searchQuery, subcategoryValueMap]);

  useDebounce(
    () => {
      getSearchSuggestions();
    },
    500,
    [searchQuery],
  );

  return (
    <div className={styles.searchContainer}>
      <span className={styles.searchButton}>
        <SearchOutlined />
      </span>
      {useMemo(
        () => (
          <Input
            value={searchQuery || ''}
            className={styles.searchInput}
            placeholder="Search for a brand, category, person, or product"
            onChange={(e) => setSearchQuery(e.target.value)}
            onPressEnter={(e) => {
              if (e.code === 'Enter') {
                searchMarketplace(searchQuery);
              }
            }}
          />
        ),
        [searchMarketplace, searchQuery],
      )}
      {showSuggestionsContainer && (
        <div className={styles.searchSuggestionsContainer}>
          {!isEmpty(searchSuggestions.categories) && (
            <div className={styles.wrapper}>
              <span className={classNames('utility-big', styles.header)}>Categories</span>
              {searchSuggestions.categories.map((category, index) => (
                <div key={`category-${index}`} className={styles.suggestion}>
                  <Link className={'utility-small'} href={suggestionShopAllLink(category)}>
                    {category.text}
                  </Link>
                </div>
              ))}
            </div>
          )}
          {!isEmpty(searchSuggestions.stores) && (
            <div className={styles.wrapper}>
              <span className={classNames('utility-big', styles.header)}>Creators</span>
              {searchSuggestions.stores.map((store, index) => (
                <div className={styles.suggestion} key={`store-${index}`}>
                  <Link
                    className={styles.store}
                    href={'/s/' + store.handle}
                    onClick={() => {
                      setShowSuggestionsContainer(false);
                    }}
                  >
                    {store.avatarUrl && (
                      <Image
                        width={23}
                        height={23}
                        src={imageTransform(store.avatarUrl, 'compact')}
                        alt="avatar"
                      />
                    )}
                    <span className="utility-small">{store.title}</span>
                  </Link>
                </div>
              ))}
            </div>
          )}
          {!isEmpty(searchSuggestions.items) && (
            <div className={styles.wrapper}>
              <span className={classNames('utility-big', styles.header)}>Items</span>
              {searchSuggestions.items.map((item, index) => (
                <div className={styles.suggestion} key={`item-${index}`}>
                  <span
                    className="utility-small"
                    onClick={() => handleSuggestionClick(true, item.text)}
                  >
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UniversalSearch;
