import axios from '@/shared/utils/axios';
import { IProduct } from '@/shared/types/product-types.interface';
import storage from '@/shared/utils/storage';
import { ANONYMOUS_USER_ID } from '@/shared/constants/app';

export async function getProductDetails(itemId: number) {
  const res = await axios.get<IProduct>(`/app/jewelry/${itemId}`);
  return res.data;
}

export async function getCategoriesWithResults() {
  const res = await axios.get<{ list: { category: string; results: number }[]; size: number }>(
    '/app/jewelry/action/categories',
    {},
  );
  return res.data;
}

export async function searchJewelry(params: { [key: string]: any }) {
  const res = await axios.get('/app/jewelry/action/full-text-search?includeVariantField=false', {
    params,
  });
  return res.data;
}

export async function getRecentlyViewedItems(params: { itemId: string | number }) {
  const res = await axios.get(
    `/app/jewelry/recommendations/${params.itemId}?count=8&anonymousUserId=${storage.get(
      ANONYMOUS_USER_ID,
    )}&scenario=PDP_I2U&logic=recently-viewed`,
  );

  return res.data;
}

export async function getSearchSuggestions(text: string) {
  const res = await axios.get(
    '/app/jewelry/action/suggest-auto-complete?includeStoreSuggests=true' +
      '&includeCategorySuggests=true' +
      '&text=' +
      text +
      '&size=4',
    {},
  );
  return res.data;
}
