import Image from 'next/image';
import Link from 'next/link';
import Spinner from '@/components/Common/Spinner';
import classNames from 'classnames';
import storage from '@/shared/utils/storage';
import {
  ANONYMOUS_USER_ID,
  MAX_QUANTITY_DISPLAY,
  MIN_QUANTITY_DISPLAY,
} from '@/shared/constants/app';
import { Badge, Button, Drawer, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { formatPrice } from '@/shared/utils/utils';
import { generateCheckout } from '@/lib/service';
import { buildQuantity, shopifyImageUrl } from '@/shared/utils/store-helper';
import { useCallback, useMemo, useState } from 'react';
import { useConfigProvider } from '@/lib/context/ConfigProvider';
import { useRouter } from 'next/router';
import { useShoppingCartProvider } from '@/lib/context/ShoppingCartProvider';
import './index.less';
import _ from 'lodash';

const ShoppingCart = ({
  size = 'small',
  className,
  position,
}: {
  size?: 'large' | 'small';
  className?: string;
  position?: 'topLeft' | 'topRight';
}) => {
  const { open, loading, total, cartItems, onOpenDrawer, onAddShop, onDeleteShopCarItem } =
    useShoppingCartProvider();
  const { isMobile } = useConfigProvider();
  const router = useRouter();

  const [checkoutLoading, setCheckoutLoading] = useState<number>(-1);

  const iconSrc = useMemo(() => {
    return size === 'large'
      ? '/images/layouts/cart-icon-120.png'
      : '/images/layouts/cart-icon-50.png';
  }, [size]);

  const getQuantitySelectorOptions = (
    cartItem: API.CartItem,
  ): { label: string; value: number }[] => {
    const moq = cartItem?.store?.isStorefrontProtected
      ? cartItem?.jewelry?.meta?.wholesaleMOQ || MIN_QUANTITY_DISPLAY
      : MIN_QUANTITY_DISPLAY;

    return buildQuantity(moq, MAX_QUANTITY_DISPLAY).map((selectOption) => {
      return {
        label: `Qty: ${selectOption}`,
        value: selectOption,
      };
    });
  };

  const isCartItemsEnabled = (item: API.CartItem): boolean => {
    return !(item.jewelry?.isEnabled && item.variantEdgeNode?.availableForSale);
  };

  const cartItemImage = (item: API.CartItem) => {
    const image =
      item?.variantEdgeNode?.image?.transformedSrc ?? item?.variantEdgeNode?.image?.originalSrc;

    return _.isEmpty(image) ? null : shopifyImageUrl(image, 'compact');
  };

  const variantTitle = (item: API.CartItem): string => {
    if (!item.variantEdgeNode) {
      return;
    }
    const title = item.variantEdgeNode.title;
    const isDefault = title.toUpperCase() === 'Default Title'.toUpperCase();
    return isDefault ? undefined : title;
  };

  const goToPdp = useCallback(
    (item: API.CartItem): void => {
      if (item.jewelryId) {
        router.push(`/shop/${item.jewelryId}`);
        onOpenDrawer(false);
      }
    },
    [onOpenDrawer, router],
  );

  const getFormatPrice = (price: string): string => {
    return formatPrice(price);
  };

  const changeQty = useCallback(
    (item: API.CartItem, quantity: number) => {
      onAddShop({
        anonymousUserId: storage.get(ANONYMOUS_USER_ID),
        jewelryId: item.jewelryId,
        variantId: item.variantId,
        quantity: quantity - item.quantity,
      });
    },
    [onAddShop],
  );

  const getCartItemTotalPrice = (data: API.CartItem[]): string => {
    let total = 0;
    data.forEach((item) => {
      if (item.variantEdgeNode) {
        total += Number(item.variantEdgeNode.price) * +item.quantity;
      }
    });
    return String(total);
  };

  const checkoutButtonText = useCallback(
    (index: number) => {
      return checkoutLoading === index ? 'Loading Checkout...' : 'Checkout';
    },
    [checkoutLoading],
  );

  const isStoreCartEnabled = (items: API.CartItem[]): boolean => {
    return items.map((item) => isCartItemsEnabled(item)).includes(true);
  };

  const proceedToCheckout = useCallback(
    async (items: API.CartItem[], index: number) => {
      if (checkoutLoading === index) {
        return;
      }
      setCheckoutLoading(index);
      const result = await generateCheckout({
        anonymousUserId: storage.get(ANONYMOUS_USER_ID),
        items: items.map((item) => ({ cartItemId: item.id })),
      });

      if (process.browser) {
        if (result?.webUrl) {
          window.location = result.webUrl;
        }
        setTimeout(() => {
          setCheckoutLoading(-1);
        }, 2000);
      }
    },
    [checkoutLoading],
  );

  const getDrawerChildren = useMemo(() => {
    if (total === 0 || cartItems?.length === 0) {
      return (
        <div className="empty-cart">
          <Image
            className="cart-icon"
            src={'/images/layouts/cart-icon-120.png'}
            alt="Pietra"
            title="Pietra"
            width={70}
            height={70}
          />
          <span className="cart-message">Your shopping cart is empty.</span>
          <Button type="primary" className="action-button" onClick={() => onOpenDrawer(false)}>
            Keep Browsing
          </Button>
        </div>
      );
    } else {
      const items = (cartItems || []).map((item, index) => {
        // Show "Print-on-demand" if is a printful order and there is another non-printful order from same store
        const showPrintfulTag =
          item.isPrintfulOrder &&
          _.find(cartItems, (cartIem) => !cartIem.isPrintfulOrder && cartIem.id === item.id);

        return (
          <div key={`cart-store-item-${index}`} className="store-cart-list">
            <div className="store-info-wrapper">
              <Image
                className="store-img"
                src={item.avatarUrl || '/images/avatar-cover.png'}
                alt={item.title}
                title={item.title}
                width={36}
                height={36}
              />
              <div className="right">
                <Link className="store-name" href={`/s/${item.handle}`}>
                  {item.title}
                </Link>
                {showPrintfulTag && <span className="print-on-demand-tag">(Print-on-demand)</span>}
              </div>
            </div>
            <div className="cart-list-wrapper">
              {(item.items || []).map((cartItem: API.CartItem, cIndex: number) => (
                <div
                  className={classNames('cart-item', {
                    'sold-out': isCartItemsEnabled(cartItem),
                  })}
                  key={`store-cart-item-${index}-${cIndex}`}
                >
                  <Image
                    className="product-img"
                    src={cartItemImage(cartItem) || '/images/avatar-cover.png'}
                    alt={cartItem.jewelry?.name}
                    title={cartItem.jewelry?.name}
                    width={75}
                    height={75}
                  />
                  <div className="right">
                    <div className="item-title" onClick={() => goToPdp(cartItem)}>
                      {cartItem.jewelry?.name || '--'}
                    </div>
                    {variantTitle(cartItem) && variantTitle(cartItem) !== cartItem.title && (
                      <span className="variant-type">{variantTitle(cartItem)}</span>
                    )}
                    <div id={`item-quantity-${cartItem.jewelry.id}`}>
                      <Select
                        defaultValue={cartItem.quantity || MIN_QUANTITY_DISPLAY}
                        size={'small'}
                        data-id={`item-quantity-${cartItem.jewelry.id}`}
                        style={{ width: 120 }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        options={getQuantitySelectorOptions(cartItem)}
                        onSelect={(value) => changeQty(cartItem, value)}
                      />
                    </div>
                    <span className="item-price">
                      <span className="label">Unit price: </span>
                      {cartItem.variantEdgeNode
                        ? getFormatPrice(cartItem?.variantEdgeNode.price)
                        : 0}
                    </span>
                  </div>
                  <CloseOutlined
                    className="remove-item"
                    onClick={() => onDeleteShopCarItem(cartItem.id)}
                  />
                </div>
              ))}
            </div>
            <div className="cart-footer-wrapper">
              <div className="utility-big subtotal">
                Subtotal:{' '}
                <span className="value">
                  {getFormatPrice(getCartItemTotalPrice(item.items || []))}
                </span>
              </div>
              <div className="btn-warp">
                {/* TODO  add logic later*/}
                {false ? (
                  <span
                    className={classNames('utility-big action-button', {
                      disabled: checkoutLoading === index,
                    })}
                  >
                    {checkoutButtonText(index)}
                  </span>
                ) : (
                  <div className="not-logged-in-buttons">
                    <span
                      className={classNames('utility-big action-button', {
                        disabled: isStoreCartEnabled(item.items),
                      })}
                      onClick={() => proceedToCheckout(item.items, index)}
                    >
                      {checkoutButtonText(index)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      });
      return <div className="items">{items}</div>;
    }
  }, [
    cartItems,
    changeQty,
    checkoutButtonText,
    checkoutLoading,
    goToPdp,
    isStoreCartEnabled,
    onDeleteShopCarItem,
    onOpenDrawer,
    proceedToCheckout,
    total,
  ]);

  return (
    <div className={classNames('shopping-car-warp', className, position)}>
      <div
        className={classNames('shopping-cart-btn-icon', {
          large: size === 'large',
        })}
        onClick={() => onOpenDrawer(true)}
      >
        <Badge count={total}>
          <Image
            className="pietra"
            src={iconSrc}
            alt="Pietra"
            title="Pietra"
            width={40}
            height={11}
          />
        </Badge>
      </div>
      <Drawer
        title={total === 0 || cartItems?.length === 0 ? false : 'Your Cart'}
        // closable={false}
        width={isMobile ? '100%' : 400}
        placement="right"
        onClose={() => onOpenDrawer(false)}
        open={open}
        rootClassName="shopping-cart-drawer-warp"
        key={'shopping-cart-drawer'}
      >
        {loading ? <Spinner /> : getDrawerChildren}
      </Drawer>
    </div>
  );
};
export default ShoppingCart;
