import type { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ShoppingCart, NavHamburger, NavMobileSearch } from '@/components/Layouts';
import styles from './index.module.less';
import { logCommonView } from '@/lib/service';
import { EventSource } from '@/shared/types/event-types';
const MobileHeader = ({ children }: { children?: ReactNode[] }) => {
  return (
    <div className={styles.mobileHeaderWarp}>
      <div className={styles.navToolbar}>
        <div className={styles.navToolbarSection}>
          <NavHamburger>{children}</NavHamburger>
          <NavMobileSearch className={styles.searchIcon} />
        </div>
        <div className={styles.navToolbarSection}>
          <Link
            href="/"
            className={styles.logo}
            onClick={() => {
              logCommonView(EventSource.WEBSITE_NAV_BAR, 'pietra-logo');
            }}
          >
            <Image
              className={styles.pietra}
              src="/images/layouts/small-logo.png"
              alt="Pietra"
              title="Pietra"
              width={40}
              height={11}
            />
          </Link>
        </div>
        <div className={styles.navToolbarSection}>
          <ShoppingCart className={styles.cartIcon} />
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
