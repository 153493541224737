import './index.less';
const Spinner = () => {
  return (
    <div className="spinner">
      <div className="load-3">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default Spinner;
