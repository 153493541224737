import { CaretDownOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import classNames from 'classnames';
import Link from 'next/link';
export interface MarketplaceNavItem {
  text: string;
  path?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}
const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    if (item?.onClick || (Array.isArray(item.dropdownItems) && item.dropdownItems.length > 0)) {
      return (
        <div
          className={classNames(styles.navItemWarp, className)}
          key={index}
          onClick={() => {
            if (item?.onClick) {
              item.onClick(item);
            }
          }}
        >
          <span className={styles.navItemText}>{item.text}</span>
          {item.dropdownItems && <CaretDownOutlined />}
          {item.dropdownItems && (
            <div className={styles.navDropdown}>
              {item.dropdownItems.map((childItem, cIndex) =>
                renderItem(childItem, `nav-dropdown-item-${cIndex}`),
              )}
            </div>
          )}
        </div>
      );
    }
    return (
      <Link
        className={classNames(styles.navItemWarp, className)}
        key={index}
        href={item?.path || '/'}
      >
        <span className={styles.navItemText}>{item.text}</span>
        {item.dropdownItems && <CaretDownOutlined />}
        {item.dropdownItems && (
          <div className={styles.navDropdown}>
            {item.dropdownItems.map((childItem, cIndex) =>
              renderItem(childItem, `nav-dropdown-item-${cIndex}`),
            )}
          </div>
        )}
      </Link>
    );
  };
  return renderItem(item, `nav-item`);
};
export default NavItem;
