import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { Drawer, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useConfigProvider } from '@/lib/context/ConfigProvider';
import { useToggle } from 'react-use';
import { useRouter } from 'next/router';
import { convertSuggestionsResponse, imageTransform, shopAllLink } from '@/shared/utils/utils';
import { EventSource } from '@/shared/types/event-types';
import { getSubcategoryValueToTextMap } from '@/shared/utils/subcategory-helper';
import { getSearchSuggestions, logCommonView } from '@/lib/service';
import styles from './index.module.less';
import Link from 'next/link';
import Image from 'next/image';

const NavMobileSearch = ({ className }: { className?: string }) => {
  const { isMobile } = useConfigProvider();
  const router = useRouter();
  const [open, toggle] = useToggle(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<{
    stores?: Array<{ [key: string]: any }>;
    categories?: Array<{ [key: string]: any }>;
    items?: Array<String>;
  }>();

  const handleSearch = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      toggle();
      router.push(
        shopAllLink(searchValue, {
          text: searchValue,
          scrollToTop: true,
        }),
      );
    },
    [router, searchValue, toggle],
  );

  const allSuggestions = useMemo(() => {
    const arr: any[] = [];
    if (searchSuggestions?.categories) {
      arr.push.apply(arr, searchSuggestions.categories);
    }
    if (searchSuggestions?.stores) {
      arr.push.apply(arr, searchSuggestions.stores);
    }
    if (searchSuggestions?.items) {
      arr.push.apply(arr, searchSuggestions.items);
    }
    return arr;
  }, [searchSuggestions?.categories, searchSuggestions?.items, searchSuggestions?.stores]);

  const handleSearchSuggestions = useCallback(async (value: string) => {
    if (value.length === 0) {
      setSearchSuggestions({});
      return;
    }
    try {
      const response = await getSearchSuggestions(value);
      if (response) {
        const subcategoryValueToTextMap = getSubcategoryValueToTextMap();
        const searchSuggestions = convertSuggestionsResponse(response, subcategoryValueToTextMap);
        setSearchSuggestions(searchSuggestions);
      } else {
        setSearchSuggestions({});
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const suggestionShopAllLink = useCallback(
    (item: any, itemParam: string, itemValue: any): string => {
      const params: { [key: string]: string } = {};
      params[itemParam] = itemValue;
      return shopAllLink(item.text, params);
    },
    [],
  );

  const handleResetSearch = useCallback(() => {
    setSearchValue('');
    setSearchSuggestions({});
    toggle();
  }, [toggle]);

  return (
    <div className={classNames(styles.navMobileSearchWarp, className)}>
      <SearchOutlined
        onClick={() => {
          logCommonView(EventSource.WEBSITE_NAV_BAR, 'search-icon');
          toggle();
        }}
      />
      <Drawer
        title={false}
        closable={true}
        width={isMobile ? '100%' : 400}
        height={'100vh'}
        placement="top"
        onClose={toggle}
        open={open}
        rootClassName={styles.navSearchDrawerWarp}
        key={'left-menu-drawer'}
      >
        <div className={styles.searchWarp}>
          <Input
            className={styles.shopSearchField}
            placeholder={'Search'}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleSearchSuggestions(e.target.value);
            }}
            onPressEnter={handleSearch}
          />
          <p className={styles.shopSearchTip}>Search products and creators.</p>
        </div>
        {allSuggestions.length > 0 && (
          <div className="search-suggestions-container">
            {searchSuggestions.categories && searchSuggestions.categories.length > 0 && (
              <div className="wrapper">
                <span className="utility-big header">Categories</span>
                {(searchSuggestions.categories || []).map((item: any, index: number) => (
                  <div className="suggestion" key={`categories-item-${index}`}>
                    <Link
                      className="utility-small"
                      href={suggestionShopAllLink(item, item.param, item.value)}
                      onClick={handleResetSearch}
                    >
                      {item.text}
                    </Link>
                  </div>
                ))}
              </div>
            )}
            {searchSuggestions.stores && searchSuggestions.stores.length > 0 && (
              <div className="wrapper">
                <span className="utility-big header">Creators</span>
                {(searchSuggestions.stores || []).map((item: any, index: number) => (
                  <div className="suggestion" key={`categories-item-${index}`}>
                    <Link className="store" href={`/s/${item.handle}`} onClick={handleResetSearch}>
                      {item.avatarUrl && (
                        <Image
                          width={23}
                          height={23}
                          src={imageTransform(item.avatarUrl, 'compact')}
                          alt="avatarUrl"
                        />
                      )}
                      <span className="utility-small">{item.title}</span>
                    </Link>
                  </div>
                ))}
              </div>
            )}
            {searchSuggestions.items && searchSuggestions.items.length > 0 && (
              <div className="wrapper">
                <span className="utility-big header">Products</span>
                {(searchSuggestions.items || []).map((item: any, index: number) => (
                  <div className="suggestion" key={`categories-item-${index}`}>
                    <Link
                      className="utility-small"
                      href={suggestionShopAllLink(item, 'text', item.text)}
                      onClick={handleResetSearch}
                    >
                      {item.text}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default NavMobileSearch;
